import { USER_LOGGED_IN, USER_LOGGED_OUT } from '../types'


export const userLoggedIn = username => ({
    type: USER_LOGGED_IN,
    username
});
export const userLoggedOut = () => ({
    type: USER_LOGGED_OUT,
})

export const LoggedIn = (username) => (dispatch) => 
    dispatch(userLoggedIn(username))

export const LoggedOut = () => (dispatch) => {
    localStorage.removeItem("authJWT")
    dispatch(userLoggedOut())
} 