import './App.css';
import React from 'react';
import 'semantic-ui-css/semantic.min.css';

import Registration from './components/Registation';
import Login from './components/Login';
import Main from './components/Main';
import Schedules from './components/Schedules';
import { Route } from 'react-router-dom';
import UserRoute from '../src/components/routes/UserRoute';

function App(props) {
  const { location } = props;
  return (
    <div>
      <Route location={location} path='/login' exact component={Login} />
      <Route
        location={location}
        path='/registration/:id'
        exact
        component={Registration}
      />
      <UserRoute location={location} path='/' exact component={Main} />
      <UserRoute location={location} path='/main' exact component={Main} />
      <UserRoute
        location={location}
        path='/schedules'
        exact
        component={Schedules}
      />
    </div>
  );
}

export default App;
