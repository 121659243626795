import React from 'react';
import { connect } from 'react-redux'
import { Route, Redirect } from 'react-router-dom'

const UserRoute = ({ isAuthenticated, component: Component, ...rest }) => (
    <Route {...rest} render={props =>
        isAuthenticated ? <Component {...props} /> : <Redirect to="/login" />}
    />
)

function MapStateToProps(state) {
    return {
        isAuthenticated: !!state.auth
    }
}

export default connect(MapStateToProps)(UserRoute)