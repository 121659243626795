import React, { Component } from 'react';
import 'semantic-ui-css/semantic.min.css';
import { Container, Header, Button, Form, Message } from 'semantic-ui-react';
import axios from 'axios';
import ShortUniqueId from 'short-unique-id';
import { EVENT_API_URL } from '../config/config';

class Registration extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fullname: '',
      phonenumber: '',
      organization: '',
      email: '',
      visibleForm: true,
      isLoading: false,
      isError: false,
      isErrorFullname: false,
      isErrorOrganization: false,
      errorMessage: '',
      event_name: '',
      schedule_name: '',
      isSubmitDiable: false
    };
  }

  onSubmit = () => {
    const EventID = parseJwt(this.props.match.params.id).EventID;
    const ScheduleID = parseJwt(this.props.match.params.id).ScheduleID;

    this.setState({
      isLoading: true
    });

    if (this.state.fullname !== '' && this.state.organization !== '') {
      const config = {
        headers: {
          ApplicationID: 'p$upkt4@ndr01d'
        }
      };
      const uid = new ShortUniqueId();
      const data = {
        Code: uid.randomUUID(8),
        Fullname: this.state.fullname,
        Email: this.state.email,
        PhoneNumber: this.state.phonenumber,
        Note: this.state.organization
      };

      axios
        .post(
          EVENT_API_URL +
            '/api/' +
            localStorage.username +
            '/events/' +
            EventID +
            '/schedules/' +
            ScheduleID +
            '/checkIn',
          data,
          config
        )
        .then(response => {
          this.setState({
            visibleForm: false,
            isLoading: false
          });
        })
        .catch(error => {
          this.setState({
            isError: true,
            errorMessage: error.response.data.Message,
            isLoading: false
          });
        });
    } else {
      this.setState({
        isError: true,
        isErrorFullname: this.state.fullname === '' ? true : false,
        isErrorOrganization: this.state.organization === '' ? true : false,
        errorMessage:
          'Please enter' +
          (this.state.fullname === '' ? ' Full Name' : '') +
          (this.state.organization === '' ? ' and Organization' : ''),
        isLoading: false
      });
    }
  };

  componentDidMount() {
    const EventID = parseJwt(this.props.match.params.id).EventID;
    const ScheduleID = parseJwt(this.props.match.params.id).ScheduleID;

    const config = {
      headers: {
        ApplicationID: 'p$upkt4@ndr01d'
      }
    };
    axios
      .get(EVENT_API_URL + '/api/events/' + EventID, config)
      .then(event => {
        this.setState({
          event_name: event.data.EventNameEN
        });
        axios
          .get(
            EVENT_API_URL +
              '/api/' +
              event.data.CreatedBy +
              '/events/' +
              EventID +
              '/schedules/' +
              ScheduleID,
            config
          )
          .then(schedule => {
            if (schedule.data != null) {
              this.setState({
                schedule_name: schedule.data.ScheduleTitle
              });
            } else {
              this.setState({
                isError: true,
                errorMessage: 'Not found or this event is not open yet',
                isSubmitDiable: true,
                isLoading: false
              });
            }
          })
          .catch(error => {
            if (error.response.status === 404) {
              this.setState({
                isError: true,
                errorMessage: error.response.statusText,
                isLoading: false
              });
            } else {
              this.setState({
                isError: true,
                errorMessage: error.response.data.Message,
                isLoading: false
              });
            }
          });
      })
      .catch(error => {
        if (error.response.status === 404) {
          this.setState({
            isError: true,
            errorMessage: error.response.statusText,
            isLoading: false
          });
        } else {
          this.setState({
            isError: true,
            errorMessage: error.response.data.Message,
            isLoading: false
          });
        }
      });
  }

  onChange = e => {
    this.setState({
      [e.target.name]: e.target.value
    });
  };

  render() {
    return (
      <div>
        <Container text style={{ paddingTop: 20 }}>
          <Header as='h4' textAlign='center'>
            <Header.Content>
              {this.state.event_name}
              <Header.Subheader>{this.state.schedule_name}</Header.Subheader>
            </Header.Content>
          </Header>

          {this.state.visibleForm ? (
            <Form
              error={this.state.isError}
              loading={this.state.isLoading}
              onSubmit={this.onSubmit}
            >
              <Form.Field>
                <label>Full Name</label>
                <Form.Input
                  name='fullname'
                  onChange={this.onChange}
                  placeholder='Full Name'
                  error={this.state.isErrorFullname}
                />
              </Form.Field>
              <Form.Field>
                <label>Email</label>
                <Form.Input
                  name='email'
                  onChange={this.onChange}
                  placeholder='Email'
                />
              </Form.Field>
              <Form.Field>
                <label>Phone Number</label>
                <Form.Input
                  name='phonenumber'
                  onChange={this.onChange}
                  placeholder='Phone Number'
                />
              </Form.Field>
              <Form.Field>
                <label>Organization</label>
                <Form.Input
                  name='organization'
                  onChange={this.onChange}
                  placeholder='Organization'
                  error={this.state.isErrorOrganization}
                />
              </Form.Field>
              <Message
                error
                header='Registration failed'
                content={this.state.errorMessage}
              />
              <Button disabled={this.state.isSubmitDiable} fluid type='submit'>
                Register
              </Button>
            </Form>
          ) : (
            <Header as='h2' textAlign='center' color='green'>
              THANK YOU FOR REGISTRATION
            </Header>
          )}
        </Container>
      </div>
    );
  }
}

function parseJwt(token) {
  var base64Url = token.split('.')[1];
  var base64 = base64Url.replace('-', '+').replace('_', '/');
  return JSON.parse(window.atob(base64));
}

export default Registration;
