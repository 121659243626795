import { USER_LOGGED_IN, USER_LOGGED_OUT } from '../types'

export default function auth(state = null, action = null) {
    switch (action.type) {
        case USER_LOGGED_IN:
            return action.username;
        case USER_LOGGED_OUT:
            return {}
        default:
            return state;
    }
}