import axios from 'axios'
import { TIME_ATTENDANCE_EVENT_API_URL, APPLICATION_ID } from '../config/config'

const config = {
    headers: {
        'ApplicationID': APPLICATION_ID
    }
}

export default {
    getScheduleByEventID: (MemberID, EventID) =>
        axios.get(TIME_ATTENDANCE_EVENT_API_URL + '/api/' + MemberID + '/events/' + EventID + '/schedules', config).then(response => response.data)
}