import React, { Component } from 'react';
import { connect } from 'react-redux'
import { Button, Menu, Container, Table, Segment } from 'semantic-ui-react'
import { LoggedOut } from '../store/actions/auth'
import { eventSelect } from '../store/actions/eventSelect'
import EventAPI from '../apis/event'

class Main extends Component {

    constructor(props) {
        super(props);
        this.state = {
            events: [],
            isLoading: false
        }
    }


    logout = () => {
        this.props.LoggedOut()
        this.props.history.push('/login')
    }

    componentDidMount() {
        this.setState({
            isLoading: true
        })
        EventAPI.getByMemberID(this.props.MemberID)
            .then(data => {
                this.setState({
                    events: data,
                    isLoading: false
                })
            }).catch(error => {
                console.log("Error = ", error)
                this.setState({
                    isLoading: false
                })
            })
    }

    onSelect = (event) => {
        this.props.eventSelect(event)
        this.props.history.push('/schedules')
    }

    render() {
        const { events } = this.state

        return (
            <Container style={{ padding: 10 }}>

                <Menu pointing>
                    <Menu.Item>
                        <img alt='PSU Phuket' style={{ width: 20 }} src={require('../resources/images/psulogo.png')} />
                    </Menu.Item>
                    <Menu.Item name='My Events' active />
                    <Menu.Menu position='right'>
                        <Menu.Item>
                            {this.props.MemberID}
                        </Menu.Item>
                        <Menu.Item>
                            <Button onClick={this.logout} negative>Logout</Button>
                        </Menu.Item>
                    </Menu.Menu>
                </Menu>
                <Segment loading={this.state.isLoading}>
                    <div style={{ marginTop: 0 }}>
                        <Table basic='very' selectable>
                            <Table.Header>
                                <Table.Row>
                                    <Table.HeaderCell collapsing>No.</Table.HeaderCell>
                                    <Table.HeaderCell>Event Name</Table.HeaderCell>
                                    <Table.HeaderCell textAlign='center'>Select</Table.HeaderCell>
                                </Table.Row>
                            </Table.Header>

                            <Table.Body>
                                {events.map((event, i) =>
                                    <Table.Row key={i}>
                                        <Table.Cell textAlign='center'>{i + 1}</Table.Cell>
                                        <Table.Cell>{event.EventNameEN}</Table.Cell>
                                        <Table.Cell collapsing><Button onClick={() => this.onSelect(event)} color='green'>Select</Button></Table.Cell>
                                    </Table.Row>
                                )}
                            </Table.Body>
                        </Table>
                    </div>
                </Segment>
            </Container>
        );
    }
}

function MapStateToProps(state) {
    return {
        MemberID: state.auth
    }
}

export default connect(MapStateToProps, { LoggedOut, eventSelect })(Main);
