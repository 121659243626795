import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  Button,
  Menu,
  Container,
  Table,
  Segment,
  Header
} from 'semantic-ui-react';
import { LoggedOut } from '../store/actions/auth';
import { scheduleSelect } from '../store/actions/scheduleSelect';
import ScheduleAPI from '../apis/schedule';
import jwt from 'jsonwebtoken';
import { JWT_SECRET } from '../config/config';

class Schedules extends Component {
  constructor(props) {
    super(props);
    this.state = {
      schedules: [],
      isLoading: false,
      isNoData: false
    };
  }

  logout = () => {
    this.props.LoggedOut();
    this.props.history.push('/login');
  };

  componentDidMount() {
    this.setState({
      isLoading: true
    });
    ScheduleAPI.getScheduleByEventID(
      this.props.MemberID,
      this.props.eventSelect.EventID
    )
      .then(data => {
        this.setState({
          schedules: data,
          isLoading: false
        });
      })
      .catch(error => {
        if (error.response.status === 404) {
          this.setState({
            isNoData: true
          });
        }
        this.setState({
          isLoading: false
        });
      });
  }

  onSelect = schedule => {
    this.props.history.push(
      '/registration/' +
        generateJWT(this.props.eventSelect.EventID, schedule.ScheduleID)
    );
  };

  render() {
    const { schedules } = this.state;

    return (
      <Container style={{ padding: 10 }}>
        <Menu pointing>
          <Menu.Item>
            <img
              alt='PSU Phuket'
              style={{ width: 20 }}
              src={require('../resources/images/psulogo.png')}
            />
          </Menu.Item>
          <Menu.Item
            name='My Events'
            onClick={() => this.props.history.push('/main')}
          />
          <Menu.Item name='Schedules' active />
          <Menu.Menu position='right'>
            <Menu.Item>{this.props.MemberID}</Menu.Item>
            <Menu.Item>
              <Button onClick={this.logout} negative>
                Logout
              </Button>
            </Menu.Item>
          </Menu.Menu>
        </Menu>
        <Segment>
          <Header textAlign='center' size='tiny'>
            {this.props.eventSelect.EventNameEN}
          </Header>
        </Segment>
        <Segment loading={this.state.isLoading}>
          <div style={{ marginTop: 0 }}>
            {!this.state.isNoData ? (
              <Table basic='very' selectable>
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell collapsing>No.</Table.HeaderCell>
                    <Table.HeaderCell>Schedule Name</Table.HeaderCell>
                    <Table.HeaderCell textAlign='center'>
                      Select
                    </Table.HeaderCell>
                  </Table.Row>
                </Table.Header>

                <Table.Body>
                  {schedules.map((schedule, i) => (
                    <Table.Row key={i}>
                      <Table.Cell textAlign='center'>{i + 1}</Table.Cell>
                      <Table.Cell>{schedule.ScheduleTitle}</Table.Cell>
                      <Table.Cell collapsing>
                        <Button
                          onClick={() => this.onSelect(schedule)}
                          color='green'
                        >
                          Select
                        </Button>
                      </Table.Cell>
                    </Table.Row>
                  ))}
                </Table.Body>
              </Table>
            ) : (
              <div>No Schedules.</div>
            )}
          </div>
        </Segment>
      </Container>
    );
  }
}

function MapStateToProps(state) {
  return {
    MemberID: state.auth,
    eventSelect: state.eventSelect
  };
}

function generateJWT(EventID, ScheduleID) {
  const generateJWT = jwt.sign(
    {
      EventID: EventID,
      ScheduleID: ScheduleID
    },
    JWT_SECRET
  );
  return generateJWT;
}

// function parseJwt(token) {
//     var base64Url = token.split('.')[1];
//     var base64 = base64Url.replace('-', '+').replace('_', '/');
//     return JSON.parse(window.atob(base64));
// }

export default connect(
  MapStateToProps,
  { LoggedOut, scheduleSelect }
)(Schedules);
