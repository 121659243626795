import React, { Component } from 'react';
import { Form, Container, Button, Grid, Message } from 'semantic-ui-react';
import PSUPassportAPI from '../apis/psupassport';
import { connect } from 'react-redux';
import { LoggedIn } from '../store/actions/auth';

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      username: '',
      password: '',
      isLoading: false,
      errorMessage: '',
      isError: false
    };
  }

  onChange = e => {
    this.setState({
      [e.target.name]: e.target.value
    });
  };

  onSubmit = async () => {
    this.setState({
      isLoading: true
    });

    await PSUPassportAPI.Authenticate(this.state.username, this.state.password)
      .then(result => {
        //console.log('Result = ', result)
        this.setState({
          isLoading: false
          //isError: true,
          //errorMessage: result.AuthenticateResult.toString()
        });
        if (result.AuthenticateResult) {
          this.props.LoggedIn(this.state.username);
          localStorage.authJWT = result.token;
          localStorage.username = this.state.username;
          // console.log(parseJwt(result.token))
          this.props.history.push('/main');
        } else {
          this.setState({
            isLoading: false,
            isError: true,
            errorMessage: 'Username or Password is incorrect'
          });
        }
      })
      .catch(error => {
        console.log('Error = ', error);
        this.setState({
          isLoading: false,
          isError: true,
          errorMessage: error
        });
      });
  };

  render() {
    return (
      <div>
        <Container style={{ paddingTop: 50 }}>
          <Grid centered columns='equal'>
            <Grid.Row>
              <Grid.Column computer={5} mobile={16} tablet={8}>
                <Message attached header='PSU Passport Login' />
                <Form
                  onSubmit={this.onSubmit}
                  className='attached fluid segment'
                  loading={this.state.isLoading}
                  error={this.state.isError}
                >
                  <Form.Field>
                    <label>Username</label>
                    <input
                      type='text'
                      name='username'
                      placeholder='Username'
                      onChange={this.onChange}
                      value={this.state.username}
                    />
                  </Form.Field>
                  <Form.Field>
                    <label>Password</label>
                    <input
                      type='password'
                      name='password'
                      placeholder='Password'
                      onChange={this.onChange}
                      value={this.state.password}
                    />
                  </Form.Field>
                  <Message
                    error
                    header='Login fail'
                    content={this.state.errorMessage}
                  />
                  <Button.Group size='large' fluid>
                    <Button color='blue'>Login</Button>
                  </Button.Group>
                </Form>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Container>
      </div>
    );
  }
}

// function parseJwt (token) {
//     var base64Url = token.split('.')[1];
//     var base64 = base64Url.replace('-', '+').replace('_', '/');
//     return JSON.parse(window.atob(base64));
// };

export default connect(
  null,
  { LoggedIn }
)(Login);
